import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import http from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useWebinarsList() {
  // Use toast
  const toast = useToast()

  const refWebinarListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'title',
      label: 'Наименование',
      sortable: true,
    },
    {
      key: 'date',
      label: 'Дата начало',
      sortable: true,
    },
    {
      key: 'course',
      label: 'Для курса',
      sortable: true,
    },
    {
      key: 'module',
      label: 'Для модула',
      sortable: true,
    },
    {
      key: 'instructor',
      label: 'Ментор',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Действие',
    },
  ]
  const perPage = ref(10)
  const totalWebinars = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('title')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refWebinarListTable.value ? refWebinarListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWebinars.value,
    }
  })

  const refetchData = () => {
    refWebinarListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchWebinars = (ctx, callback) => {
    http
      .post('https://edu.startupchoikhona.tj/backend/api/bbb-meeting/index', {
        search: searchQuery.value,
        length: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const webinars = response.data.data.items
        callback(webinars)
        totalWebinars.value = response.data.data.total_rows
      })
      .catch(() => {
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Ошибка выборки списка курсов',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const deleteWebinar = (idWebinar) => {
    Swal.fire({
      title: 'Вы действительно хотите удалить этот вебинар?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        cancelButton: 'btn btn-outline-danger ml-1',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(result => {
        if (result.value && idWebinar > 0) {
          http
            .post(`https://edu.startupchoikhona.tj/backend/api/bbb-meeting/delete/${idWebinar}`, {})
            .then(response => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Успешно удалено.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при удалении вебинара',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Удаление вебинара отменено',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  return {
    fetchWebinars,
    deleteWebinar,
    tableColumns,
    perPage,
    currentPage,
    totalWebinars,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWebinarListTable,
    refetchData,
  }
}
