<template>
  <b-sidebar
      id="add-new-webinar-sidebar"
      :visible="isAddNewWebinarSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="sideBarShown"
      @hidden="resetForm"
      :no-close-on-backdrop="true"
      @change="(val) => $emit('update:is-add-new-webinar-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ webinarId > 0 ? 'Редактировать вебинар' : 'Добавить новый вебинар'}}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>


      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Наименование -->
          <validation-provider
              #default="validationContext"
              name="Наименование"
              rules="required"
          >
            <b-form-group
                label="Наименование"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="userData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Описание -->
          <b-form-group
              label="Описание"
              label-for="description"
          >
            <quill-editor
                id="description"
                v-model="userData.description"
                :options="editorOption"
            />
          </b-form-group>

          <!-- Дата -->
          <b-form-group
              label="Дата"
              label-for="description"
          >
            <vueflatpickr
                :value="userData.date"
                class="form-control"
                :config="{ dateFormat: 'd.m.Y' }"
            />
          </b-form-group>

          <!-- Время -->
          <b-form-group
              label="Время"
              label-for="description"
          >
            <vueflatpickr
                v-model="userData.time"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}"
            />
          </b-form-group>

          <!-- Ментор -->
          <validation-provider
              #default="validationContext"
              name="ментор"
              rules="required"
              v-if="userDataStorage.roles.length > 0 && userDataStorage.roles[0] === 'admin'"
          >
            <b-form-group
                label="Ментор"
                label-for="mentor"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="userData.instructor_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="mentorOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="mentor"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Курс -->
          <validation-provider
              #default="validationContext"
              name="курс"
              rules="required"
          >
            <b-form-group
                label="Курс"
                label-for="user-role"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="userData.course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="courseOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
                  @input="getModules"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Модуль курса -->
            <b-form-group
                label="Модуль"
                label-for="module-course"
            >
              <v-select
                  v-model="userData.module_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="moduleOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="module-course"
              />
            </b-form-group>

          <!-- Пароль ментор -->
          <validation-provider
              #default="{ errors }"
              name="пароль ментор"
              rules="required|min:8"
              v-show="false"
          >
            <b-form-group
                label-for="moderator_password"
                label="Пароль ментор"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="moderator_password"
                    v-model="userData.moderator_password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="moderator_password"
                    placeholder="············"
                    autocomplete="off"
                    trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Пароль студент -->
          <validation-provider
              #default="{ errors }"
              name="пароль студент"
              rules="required|min:8"
              v-show="false"
          >
            <b-form-group
                label-for="attendee_password"
                label="Пароль студент"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="attendee_password"
                    v-model="userData.attendee_password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="attendee_password"
                    placeholder="············"
                    autocomplete="off"
                    trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                  ref="previewEl"
                  :src="userData.avatar"
                  :text="avatarText(userData.title)"
                  :variant="`light-primary`"
                  size="90px"
                  rounded
                  @click="$refs.refInputEl.click()"
              />
            </template>
            <div class="d-flex flex-wrap">
              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="inputImageRenderer"
                    accept="image/*"
                >
                <feather-icon
                    icon="DownloadIcon"
                    class="d-inline"
                />
              </b-button>
              <b-button
                  variant="outline-secondary"
                  class="ml-1"
              >
                <feather-icon
                    icon="TrashIcon"
                    class=""
                />
              </b-button>
            </div>
          </b-media>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Сохранить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, regex } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import http from '@axios'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
// Right way to import locale
import { Russian } from 'flatpickr/dist/l10n/ru'
import Vueflatpickr from 'vue-flatpickr-component'

Quill.register('modules/imageResize', ImageResize)
// default locale is now Russian
flatpickr.localize(Russian)

export default {
  components: {
    BSidebar,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageResize,
    Vueflatpickr,

  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewWebinarSidebarActive',
    event: 'update:is-add-new-webinar-sidebar-active',
  },
  props: {
    isAddNewWebinarSidebarActive: {
      type: Boolean,
      required: true,
    },
    webinarId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
      mentorOptions: [],
      courseOptions: [],
      moduleOptions: [],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {
          },
          toolbar: {
            container:  [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    sideBarShown() {
      this.getMentors()
      this.getCourses()
      this.getWebinar()
    },
    getMentors() {
      this.$http
        .get(`https://edu.startupchoikhona.tj/backend/api/users`, {
          params: {
            role_id: 3,
          }
        })
        .then(response => {
          this.mentorOptions = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getCourses() {
      this.$http
        .get(`https://edu.startupchoikhona.tj/backend/api/courses`, {
        })
        .then(response => {
          this.courseOptions = response.data.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getModules(status = true) {
      if(status) {
        this.userData.module_id = null
      }
      this.moduleOptions = []
      this.$http
        .post(`https://edu.startupchoikhona.tj/backend/api/course/${this.userData.course_id}/modules`, {
        })
        .then(response => {
          const { data } = response
          Array.from(data, value => {
            this.moduleOptions.push({
              value: value.id,
              label: value.name,
            })
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getWebinar() {
      if (this.webinarId > 0) {
        this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/bbb-meeting/${this.webinarId}/view`, {
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.userData = {
              instructor_id: data.instructor_id,
              course_id: data.course_id,
              module_id: data.module_id,
              title: data.title,
              description: data.description,
              attendee_password: data.attendee_password,
              moderator_password: data.moderator_password,
              date: data.date,
              time: data.time,
              avatar: null,
              logo: null,
            }
            this.getModules(false)
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.userData = {
          instructor_id: null,
          course_id: null,
          module_id: null,
          title: '',
          description: '',
          attendee_password: '12345678',
          moderator_password: '12345678',
          date: '',
          time: '',
          avatar: null,
          logo: null,
        }
      }
    },
  },
  setup(props, { emit }) {
    const userDataStorage = JSON.parse(localStorage.getItem('userData'))

    const blankUserData = {
      instructor_id: null,
      course_id: null,
      module_id: null,
      title: '',
      description: '',
      attendee_password: '1234567890',
      moderator_password: '12345678',
      date: '',
      time: '',
      avatar: null,
      logo: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'avatar') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.value.avatar = base64
      userData.value.logo = refInputEl.value.files[0]
    })

    const onSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userData.value)
      if (props.webinarId > 0) {
        http.post(`https://edu.startupchoikhona.tj/backend/api/bbb-meeting/update/${props.webinarId}`, formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-webinar-sidebar-active', false)
          })
      } else {
        http.post('https://edu.startupchoikhona.tj/backend/api/bbb-meeting/create', formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-webinar-sidebar-active', false)
          })
      }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    localize('ru', ru)
    return {
      userDataStorage,
      avatarText,
      refInputEl,
      previewEl,
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
    }
  },
  created() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-webinar-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#description .ql-editor {
  min-height: 300px;
}
</style>
